:root {
  --main-color: #1C4276;
  --main-light-color: #e6edf7; 
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  min-height: 100px;
  width: 100vw;
}

.nav-logo {
  position: absolute;
  top: 5px;
  left: 50.5%;
  transform: translate(-50%, 7%);
  z-index: 1;
}


.nav-items {
  font-weight: 600;
}

.toggle-button {
  background-color: var(--main-light-color);
}

#navbar-body {
  background-color: white;
  width: 100%;
}

logo-ani {
  height: 90vh;
}

@media (max-width: 767px) {
  #basic-navbar-nav div {
    background-color: hsla(0, 100, 100, 0.5);
    width: 100%
  }

  .nav-items-left {
    background-color: hsla(0, 100, 100, 0.5);
  }
  
  .nav-items-right {
    background-color: hsla(0, 100, 100, 0.5);
  }

  .swiper {
    width: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    height: 350px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
    background-color: none;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .navbar {
    height: 110px;
    margin: auto;
  }
  
  .nav-logo {
    top: 10px;
  }

  .nav-items-border {
    border-bottom: 5px solid var(--main-light-color);;
    width: 100%;
  }

  .nav-items-border-active {
    animation: border_anim .2s linear forwards;
    width: 100%;
    border-bottom: 5px solid #cbbb70;
  }

  @keyframes border_anim {
    0%{
      width: 60%;
    }
    100%{
      width: 100%;
    }
  }

  .nav-items-left {
    margin-top: 60px;
    margin-left: -8px;
    margin-bottom: -8px;
  }
  
  .nav-items-right {
    margin-top: 60px;
    margin-right: -8px;
    margin-bottom: -8px;
    position: relative;
    text-align: right;
  }

  #about {
    width: 100%;
    margin: auto;
  }

  #event {
    width: 100%;
    margin: auto; 
  }

  .about-boxes {
    height: 60vh;
  }

  .swiper {
    width: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    height: 450px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 400px;
    background-color: none;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

svg {
 width: 100%;
 height: 100%;
  padding: 30px 30px 30px 30px;
}

.about-slide {
  margin-top: 100px;
}

.about-box p {
  margin-top: 15px;
  margin-bottom: 15px;
}


.about-box img {
  margin-top: 15px;
  margin-bottom: 15px;
}

.content-slide img {
  margin: 40px 30px 40px 30px
}

.content-slide p {
  margin: 40px 30px 40px 30px
}

.responsive {
  width: 100%;
  height: auto;
}

.about-logo {
  width: 60%;
  height: auto;
}

.about-title {
  text-align: center;
  color: var(--main-color);
}

.member-title {
  text-align: center;
  color: var(--main-color);
}

#about {
  padding-top: 200px;
}

#event {
  padding-top: 150px;
}

#member {
  padding-top: 150px;
}

#contact {
  padding-top: 150px;
}

.about-box p {
  font-size: 1rem;
}

.google-calender {
  display: block;
  margin: auto;
}

h1 {
  text-align: center;
  color: var(--main-color); 
}

h2 {
  text-align: center;
  color: var(--main-color); 
}

h3 {
  text-align: center;
  color: var(--main-color); 
}



.member-qutoe-icon {
  font-size: 48px;
  color: var(--main-light-color);
}

.member-card-header {
  background-color: white !important;
}

.contact-background {
  background-color:var(--main-light-color); 
  padding-top: 1px;
  min-height: 90vh;
} 

.contact-form {
  width: 90%;
  margin: auto;
}

.contact-response {
  color: #c7b35a;
  text-align: center;
  padding-top: 5px;
}

#about {
  margin: auto;
}

.logo-color {
  filter: invert(19%) sepia(55%) saturate(1116%) hue-rotate(183deg) brightness(100%) contrast(94%);
  max-width: 300px; 
}

#member {
  margin: auto;
}

.member-accordion-header {
  text-align: center;
}

.member-accordion {
  max-width: 700px;
  margin: auto;
}

#event {
  margin: auto;
}

.contact-title {
  margin: 20px auto 20px auto;
}

.member-title {
  margin: 20px auto 20px auto;
}

/*svg animation below*/

g[id="small-circle"] {
  animation-duration: 0.35s;
  animation-name: s;
  visibility: hidden;
  animation-delay: 1.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

g[id="large-circle"] {
  animation-duration: 0.35s;
  animation-name: s;
  visibility: hidden;
  animation-delay: 2.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

g[id="text"] {
  animation-duration: 0.35s;
  animation-name: s;
  visibility: hidden;
  animation-delay: 3.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

g[id="logo"] {
  animation-duration: 0.35s;
  animation-name: s;
  visibility: hidden;
  animation-delay: 4.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes s {
  from {
    visibility: hidden;
    opacity: 40%;
  }

  to {
    visibility: visible;
    opacity: 100%;
  }
}

.swiper {
  width: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  height: 350px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  background-color: none;
}

.swiper-slide img {
  display: block;
  width: 300px;
}