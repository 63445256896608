$primary: #1C4276;
$link-color: #1C4276;
$secondary: #e6edf7;

$navbar-light-color: #1C4276;;
$navbar-light-hover-color: #e6edf7;
$navbar-light-active-color: #1C4276;

$headings-font-weight: 900;

@import "~bootstrap/scss/bootstrap";

.contact {
    padding-bottom: 100px; // Adjust this value as necessary
}

body {
    margin-bottom: 100px; // Adjust this value based on the height of your footer
}


.footer {
    background-color: #1c4275;
    color: #a78155;
    text-align: center;
    padding: 5px 0;
    // position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}